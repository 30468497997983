import React, { useState } from 'react'
import styled from 'styled-components'
//COMPONENTS
import Slide from './Slide'
import Arrow from './Arrow'


const SliderWrapper = styled.div`
    overflow: hidden;
    position: relative;
`;

const SliderContent = styled.div`
  //transform: translateX(-${props => props.translate}px);
  transform: translateX(-${props => props.translate}%);
  transition: transform ease-out ${props => props.transition}s;
  height: 100%;
  //width: ${props => props.width}px;
  width: calc(${props => props.w} * 100%);
  display: flex;
`
/**
 * @function Slider
 */

const Slider = (props) => {
    //const getWidth = () => window.innerWidth

    const [state, setState] = useState({
        activeIndex: 0,
        translate: 0,
        transition: 0.45
    })

    const { translate, transition, activeIndex } = state

    const nextSlide = () => {
        if (activeIndex === props.slides.length - 1) {
            return setState({
                ...state,
                translate: 0,
                activeIndex: 0
            })
        }

        setState({
            ...state,
            activeIndex: activeIndex + 1,
            translate: (activeIndex + 1) * (100 / props.slides.length),
            //translate: (activeIndex + 1) * getWidth()
        })
    }

    const prevSlide = () => {
        if (activeIndex === 0) {
            return setState({
                ...state,
                //translate: (props.slides.length - 1) * getWidth(),
                translate: (props.slides.length - 1) * (100 / props.slides.length),
                activeIndex: props.slides.length - 1
            })
        }

        setState({
            ...state,
            activeIndex: activeIndex - 1,
            //translate: (activeIndex - 1) * getWidth(),
            translate: (activeIndex - 1) * (100 / props.slides.length)
        })
    }

    return (
        <SliderWrapper>
            <SliderContent
                translate={translate}
                transition={transition}
                //width={getWidth() * props.slides.length}
                w={props.slides.length}
            >
                {/* {props.children} */}
                {props.slides.map((slide, i) => (
                    <Slide
                        key={slide + i}
                        content={slide.file.childImageSharp}
                        alt={slide} />
                ))}
            </SliderContent>
            <Arrow
                handleClickPrev={prevSlide}
                handleClickNext={nextSlide} />
        </SliderWrapper>
    )
}
export default Slider