import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Div = styled.div`
    //height: 400px;
    width: 100%;
`;

const Slide = ({ content, alt }) => {

    return (
        <Div>
            <GatsbyImage
                alt={alt.alt}
                image={content.gatsbyImageData} />
        </Div>
    )
}

export default Slide