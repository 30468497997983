import React from "react"
import { graphql } from 'gatsby'
//import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
//STYLES
import { ContainerMob, CardWrapperFull, CardWrapper } from '../utils/utils'
import { H1, H6, H2, Label, LabelBW, Text } from '../utils/typo'
import { theme } from "../utils/theme";
//COMPONENTS
import Layout from '../layout/layoutBW'
import Seo from '../components/seo/SEO'
import Button from '../components/button/Button'
import Slider from '../components/slider/Slider'
import CardSimple from "../components/portfolioCard/CardSimple"

const PageWrapper = styled.div`
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;

    ${down('lg')} {
    grid-template-columns: 1fr 1fr;
    }

    ${down('md')} {
    grid-template-columns: 2fr 3fr;
    }
  
    ${down('sm')} {
    grid-template-columns: 1fr;
    padding-top: 10rem;
    }

    ${down('xs')} {
    grid-template-columns: 1fr;
    }
`;

const MiniSection = styled.div`
    position: relative;
    background-color: ${props => props.theme.color.grey8};
    padding-top: 3rem;
    padding-bottom: 3rem;
`;
const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    hr {
    width: 80px;
    margin-left: 0;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Tag = styled.p`
    margin-right: .5rem;
    margin-bottom: .5rem;
    padding: .3rem 1rem;
    color: ${props => props.bgcolor || props.theme.color.white};
    background-color: ${props => props.bgcolor || props.theme.color.grey1};
    border-radius: 10px;
`;

const Portfolio = ({
    data: {
        mdx: {
            frontmatter: { title, url, images, intro, slug, client, myRole, time, techStack }
        },
        allMdx: {
            nodes
        },
    },
}) => {

    return (
        <div>
            <Seo
                title={title}
                slug={slug} />
            <Layout>
                <ContainerMob>
                    <PageWrapper>
                        <InfoWrapper>
                            <LabelBW>projekt</LabelBW>
                            <H1>{title}</H1>
                            <hr />
                            <H6
                                weight='500'
                                colorFont={theme.color.font}>
                                {intro}
                            </H6>
                            {title === null ? (
                                <></>
                            ) : (
                                <Button
                                    href={url}
                                    target='blank'>
                                    Zobacz online<b>—</b>—{`>`}
                                </Button>
                            )}
                            <CardWrapperFull xl='2' l='2' m='2' xs='2' mt='-1rem'>
                                <div>
                                    <LabelBW mt='3rem' mb='1rem'>klient</LabelBW>
                                    <Text>
                                        {client}
                                    </Text>
                                </div>
                                <div>
                                    <LabelBW mt='3rem' mb='1rem'>czas</LabelBW>
                                    <Text>
                                        {time}
                                    </Text>
                                </div>
                            </CardWrapperFull>

                            <LabelBW mt='2rem' mb='1rem'>nasze zadania</LabelBW>
                            <ul>
                                {myRole.map((item) => (
                                    <li key={myRole.indexOf(item) + 1}>{item}</li>
                                ))}
                            </ul>


                            <LabelBW mt='3rem' mb='1rem'>tech stack</LabelBW>
                            <TagWrapper>
                                {techStack.map((tag) => (
                                    <Tag key={techStack.indexOf(tag) + 1}>
                                        {tag}
                                    </Tag>))}
                            </TagWrapper>
                        </InfoWrapper>

                        <ImageWrapper>
                            <Slider slides={images} />
                            {/* <GatsbyImage
                                alt='ola'
                                image={mainImage.file.childImageSharp.gatsbyImageData} /> */}
                        </ImageWrapper>
                    </PageWrapper>
                </ContainerMob>

                <MiniSection>
                    <ContainerMob>
                        <Label>Portfolio</Label>
                        <H2>Nasze pozostałe realizacje</H2>
                    </ContainerMob>
                </MiniSection>

                <CardWrapper
                    colGap='2rem'
                    rowGap='2rem' mb='0'
                    xl='3' l='3' m='3'
                >
                    {nodes.map((node) => {

                        //const mainImage = node.frontmatter.images.find(x => x.title === 'mainImage');
                        //const index = node.frontmatter.images.indexOf(mainImage)

                        return (
                            <CardSimple
                                key={nodes.indexOf(node)}
                                name={node.frontmatter.title}
                                shortList={node.frontmatter.shortList}
                                link={node.frontmatter.slug}
                                image={node.frontmatter.mainImage.file.childImageSharp.gatsbyImageData}
                                alt={node.frontmatter.mainImage.alt}
                            />
                        )
                    })}
                </CardWrapper>
            </Layout>
        </div>
    );
};
export default Portfolio

export const query = graphql`
query ($slug: String!){
    mdx(frontmatter: {slug: {eq: $slug}}) {
        body
        frontmatter {
            id
            idPage
            shortList
            title
            url
            intro
            client
            time
            myRole
            techStack
            slug
            mainImage{
                alt
                file {
                    childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          aspectRatio: 1
                          placeholder: BLURRED
                          )
                      }
                }
            }
            images {
                alt
                title
                file {
                    childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          placeholder: BLURRED
                          )
                      }
                }
            }
        }
    }

    allMdx (
        filter: {frontmatter: {idPage: {eq: "portfolio"}}}
        sort: {order: DESC, fields: frontmatter___id}
        ){
        nodes {
            body
            frontmatter {
                title
                intro
                slug
                shortList
                mainImage{
                    alt
                    file {
                        childImageSharp {
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              aspectRatio: 1
                              placeholder: BLURRED
                              )
                          }
                    }
                }
                images {
                    alt
                    title
                    file {
                        childImageSharp {
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              placeholder: BLURRED
                              )
                          }
                    }
                }
            }
        }
    }
}
`