import React from 'react'
import styled from 'styled-components'
import { theme } from '../../utils/theme'
//import leftArrow from '../img/left-arrow.svg'
//import rightArrow from '../img/right-arrow.svg'

const ArrowWrapper = styled.div`
    display: flex;
    //padding: 0 .7rem 0 .7rem;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    //margin-top: -20px;
    //transform: translatey(-50%);
    height: auto;
    width: 100%;
    align-items: center;
`;

const Left = styled.div`
    cursor: pointer;
    background-color: ${props => props.theme.color.grey1};
    padding: 0 .5rem .2rem .5rem;

     p {
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        color: ${props => props.theme.color.white};
        //font-family: ${theme.font.special};
     }
    
`;

const Right = styled(Left)`
    
`;

const Arrow = (props) => (
    <ArrowWrapper>
        <Left onClick={props.handleClickPrev}>
            <p>
                {'<'}—
            </p>
        </Left>
        <Right onClick={props.handleClickNext}>
            <p>
                —{'>'}
            </p>
        </Right>
    </ArrowWrapper>
)

export default Arrow